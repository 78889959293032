#dashboard header {
  position: fixed;
  top: 0;
  padding: 0;
  z-index: 9;
  width: 100%;
}

#dashboard .top-nav {
  display: flex;
  background-color: var(--color-grey-darker);
  backdrop-filter: blur(calc(100vw * 10/1900));
  justify-content: space-between;
  padding: 0 var(--spacing-smallest);
  position: relative;
  z-index: 10;
}

#dashboard .top-nav #logo {
  padding: var(--spacing-smallest) 0;
}

#dashboard .top-nav #logo .img-holder {
  display: inline-block;
}

#dashboard .top-nav #logo .img-holder img {
  height: calc(100vw* 35 / 1900);
}

#dashboard .top-nav .profile {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-smallest) var(--spacing-smaller);
  background-color: var(--color-grey-light);
  border-radius: var(--border-radius-regular);
  cursor: pointer;
}

#dashboard .top-nav .profile .profile-image {
  display: flex;
  width: calc(100vw* 25 / 1900);
  height: calc(100vw* 25 / 1900);
  background-color: rgb(90, 90, 90);
  border-radius: 50%;
  margin-right: var(--spacing-smallest);
  font-size: var(--font-size-desc-medium);
  font-weight: 700;
  align-items: center;
  justify-content: center;
  color: var(--color-grey-lighter);
}

#dashboard .top-nav .profile .profile-name {
  display: inline-block;
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  line-height: 1;
}

#dashboard .top-nav .profile-main-holder {
  position: relative;
  display: inline-flex;
  align-items: center;
  z-index: 999;
}

#dashboard .top-nav .profile-holder {
  position: relative;
}

#dashboard .top-nav .profile-holder .profile-menu {
  position: absolute;
  top: calc(100% - var(--spacing-small));
  right: 0;
  width: 100%;
  padding-top: var(--spacing-small);
  background-color: var(--color-grey);
  backdrop-filter: blur(calc(100vw * 10/1900));
  border-radius: calc(100vw * 15/1900);
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}

#dashboard .top-nav .profile-holder:hover .profile-menu {
  opacity: 1;
  visibility: visible;
  transition: var(--transition);
}

#dashboard .top-nav .profile-holder .profile-menu li {
  display: flex;
  margin-bottom: 0;
}

#dashboard .top-nav .profile-holder .profile-menu li a,
#dashboard .top-nav .profile-holder .profile-menu li button {
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  text-transform: capitalize;
  text-align: left;
  text-decoration: none;
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  padding: var(--spacing-smaller) var(--spacing-smaller);
  margin-bottom: 0;
  color: var(--color-light);
  cursor: pointer;
}

#dashboard .top-nav .profile-holder .profile-menu li a:hover,
#dashboard .top-nav .profile-holder .profile-menu li button:hover {
  background-color: var(--color-grey-dark);
  backdrop-filter: blur(calc(100vw * 10/1900));
}

#dashboard .top-nav .profile-holder .profile-menu li a svg,
#dashboard .top-nav .profile-holder .profile-menu li button svg {
  width: calc(100vw * 20/1900);
  margin-right: var(--spacing-smallest);
}

#dashboard .top-nav .profile-main-holder .notifications {
  margin: var(--spacing-smaller);
}

#dashboard .top-nav .profile-main-holder .notifications button {
  font-size: var(--font-size-desc-medium);
  background-color: transparent;
  border: none;
  position: relative;
  cursor: pointer;
}

#dashboard .top-nav .profile-main-holder .notifications button .notif-count {
  display: inline-block;
  position: absolute;
  width: calc(100vw * 15/1900);
  height: calc(100vw * 15/1900);
  background-color: var(--color-warning);
  border-radius: 50%;
  font-size: var(--font-size-desc-smaller);
  font-weight: 700;
  line-height: calc(100vw * 15/1900);
  text-align: center;
  top: calc(100vw * -7.5/1900);
  right: calc(100vw * -7.5/1900);
}
/* 
#dashboard .main-nav nav .team {
  display: none;
}
#dashboard .main-nav nav .admin {
  display: block;
} */


#dashboard .main-nav nav {
  position: relative;
}

#dashboard .main-nav nav .view-toggle button {
  padding: var(--spacing-smaller) var(--spacing-smallest);
  display: inline-block;
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  text-decoration: none;
  transition: var(--transition);
  backdrop-filter: blur(calc(100vw * 10/1900));
  cursor: pointer;
  background-color: var(--color-grey-light-opac);
  backdrop-filter: blur(calc(100vw * 10/1900));
  position: absolute;
  border: none;
  top: 0;
  right: 0;
}

#dashboard .main-nav nav ul {
  display: flex;
  background-color: var(--color-grey);
}

#dashboard .main-nav nav ul li {
  position: relative;
}

#dashboard .main-nav nav ul li a {
  padding: var(--spacing-smaller) var(--spacing-smallest);
  display: inline-block;
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  text-decoration: none;
  transition: var(--transition);
  backdrop-filter: blur(calc(100vw * 10/1900));
  cursor: default;
}


#dashboard .main-nav nav ul li a[href] {
  cursor: pointer;
}

#dashboard .main-nav nav ul li a:hover,
#dashboard .main-nav nav ul li a.active,
#dashboard .main-nav nav ul li.active > a {
  background-color: var(--color-grey-lighter-opac);
  transition: var(--transition);
}

#dashboard .main-nav nav ul li .sub {
  position: absolute;
  flex-direction: column;
  min-width: calc(100vw * 200 / 1900);
  background-color: var(--color-grey-light-opac);
  backdrop-filter: blur(calc(100vw * 10/1900));
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

#dashboard .main-nav nav ul li:hover .sub {
  transition: var(--transition);
  opacity: 1;
  visibility: visible;
}

#dashboard .main-nav nav ul li .sub a {
  display: block;
}

#dashboard .main-nav .current-page,
#dashboard .main-nav .menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}


/* small devices */
@media only screen and (max-width: 699px) {
  #dashboard .top-nav #logo .img-holder img {
    height: calc(100vw* 35 / 399);
  }

  #dashboard .top-nav .profile {
    padding: var(--spacing-smaller) var(--spacing-small);
  }

  #dashboard .top-nav .profile .profile-image {
    display: flex;
    width: calc(100vw* 25 / 399);
    height: calc(100vw* 25 / 399);
  }

  #dashboard .top-nav .profile-holder .profile-menu li a,
  #dashboard .top-nav .profile-holder .profile-menu li button {
    padding: var(--spacing-small) var(--spacing-small);
  }

  #dashboard .top-nav .profile-holder .profile-menu li a svg,
  #dashboard .top-nav .profile-holder .profile-menu li button svg {
    width: calc(100vw* 20 / 399);
    font-size: var(--font-size-desc-medium);
  }

  #dashboard .main-nav .current-page,
  #dashboard .main-nav .menu-toggle {
    display: block;
  }

  #dashboard .main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-small);
    position: relative;
    background: var(--color-grey);
    backdrop-filter: blur(calc(100vw * 10/399));
  }

  #dashboard .main-nav .current-page {
    font-size: var(--font-size-desc-medium);
    font-weight: 300;
    margin: 0;
  }

  #dashboard .main-nav nav {
    display: block;
    position: fixed;
    top: calc(100vw * 40/399);
    right: -100vw;
    width: 100%;
    transition: var(--transition);
    background: var(--color-grey-dark);
  }

  #dashboard .main-nav.open nav {
    right: 0;
    transition: var(--transition);
  }

  #dashboard .main-nav ul {
    flex-direction: column;
    background-color: transparent;
    padding: 0;
    min-height: 100vh;
  }

  #dashboard .main-nav nav ul li a{
    display: block;
    padding: var(--spacing-small);
  }

}