#sidebar .sidebar-container {
  position: fixed;
  width: calc(100vw * 400/1900);
  height: 100vh;
  top: 0;
  right: calc(-1 * 100vw * 400/1900);
  background: white;
  padding: var(--spacing-big) var(--spacing-normal);
  box-shadow: 0 -16px 16px rgba(9, 9, 11, 0.75);
  z-index: 11;
  transition: var(--transition);
}

#sidebar.active .sidebar-container {
  right: 0;
  transition: var(--transition);
}

#sidebar .sidebar-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}

#sidebar.active .sidebar-overlay {
  opacity: 1;
  visibility: visible;
  transition: var(--transition);
}

#sidebar .sidebar-container .content {
  padding: 0 var(--spacing-normal)
}

#sidebar .active .content {
  width: 100%;
}

#sidebar .sidebar-container .sidebar-toggle {
  position: absolute;
  background: var(--color-light);
  color: var(--color-dark);
  padding: var(--spacing-smallest) var(--spacing-small);
  transform: rotate(-90deg);
  top: 25%;
  left: calc(-1 * 100vw * 105/1900);
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
  box-shadow: -4px -8px 8px rgba(9, 9, 11, 0.5);
  width: calc(100vw * 175/1900);
  text-align: center;
  font-size: var(--font-size-desc-medium);
  text-decoration: none;
}

#sidebar .sidebar-container .above-subtitle {
  font-size: var(--font-size-title-smaller);
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
  color: var(--color-dark);
  margin-bottom: var(--spacing-small);
}

#sidebar .sidebar-container .contact {
  margin-bottom: var(--spacing-normal)
}

#sidebar .sidebar-container .contact .contact-name,
#sidebar .sidebar-container .contact .contact-phone,
#sidebar .sidebar-container .contact .contact-email {
  text-transform: none;
  color: var(--color-dark);
  margin-bottom: 0;
}

#sidebar .sidebar-container .contact .contact-name {
  margin: 0;
  font-size: var(--font-size-desc-medium);
  font-weight: 600;
  letter-spacing: 0;
}

#sidebar .sidebar-container p,
#sidebar .sidebar-container .contact .contact-phone,
#sidebar .sidebar-container .contact .contact-email {
  font-size: var(--font-size-desc-normal)
}

#sidebar .sidebar-container p,
#sidebar .sidebar-container .contact .contact-phone a,
#sidebar .sidebar-container .contact .contact-email a {
  color: var(--color-dark);
}

#sidebar .sidebar-container .contact .contact-phone a,
#sidebar .sidebar-container .contact .contact-email a {
  text-decoration: underline;
}

#sidebar .sidebar-container .contact .contact-phone a:hover,
#sidebar .sidebar-container .contact .contact-email a:hover {
  color: var(--color-mrt-green);
}