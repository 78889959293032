.Bar,
.Pie {
  width: 100% !important;
  height: 100% !important;
}

#dashboard .content {
  margin-top: calc(100vw* 104 / 1900);
}

#dashboard .content section {
  padding: 0;
  margin: 0;
}

#dashboard .content iframe {
  border: none !important;
}

#dashboard .content.website iframe {
  height: calc(100vw* 2250 / 1900);
}

#dashboard .content section h2 {
  font-size: var(--font-size-desc-medium);
  font-weight: 600;
  line-height: 1;
  margin: var(--spacing-smaller) var(--spacing-small);
  color: var(--color-grey-lighter);
}

#dashboard .content .process-items {
  display: flex;
  gap: var(--spacing-small);
  margin-bottom: var(--spacing-small);
  justify-content: center;
}

#dashboard .content .process-items .process-item {
  flex: 0 0 calc(100%/6.55);
  background-color: var(--color-grey-darker);
  padding: var(--spacing-smaller);
  border-radius: var(--border-radius-small);
  position: relative;
}

#dashboard .content .process-items .process-item .arrow {
  position: absolute;
  font-size: var(--font-size-desc-big);
  top: var(--spacing-smaller);
  right: calc(-1 * 100vw * 20/1900);
}

#dashboard .content .process-items .process-item .title {
  font-size: var(--font-size-desc-normal);
  font-weight: 700;
  padding-bottom: var(--spacing-smallest);
  margin-bottom: var(--spacing-smallest);
  border-bottom: calc(100vw * 1/1900) solid var(--color-light);
}

#dashboard .content .process-items .process-item .desc {
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  line-height: var(--font-size-desc-medium);
  margin-bottom: var(--spacing-smallest);
  align-items: center;
}

#dashboard .content .process-items .process-item .desc img {
  height: var(--font-size-desc-small);
  margin-right: var(--spacing-smallest);
  display: inline-block;
}

#dashboard .content .process-items .process-item .top-line {
  border-top: calc(100vw * 1/1900) solid var(--color-light);
  padding-top: var(--spacing-smallest);
}

#dashboard .content .process-items .process-item ul {
  list-style-type: disc;
  padding-left: var(--spacing-smaller);
}

#dashboard .content .website-release {
  padding: var(--spacing-small)
}

#dashboard .content .website-release h2 {
  font-size: var(--font-size-desc-medium);
  font-weight: 600;
  line-height: 1;
  margin: 0 0 var(--spacing-small);
  color: var(--color-mrt-green);
}

#dashboard .content .website-release .website-release-item .title {
  font-weight: 300;
  padding-bottom: 0;
  margin-bottom: var(--spacing-smaller);
  font-size: var(--font-size-desc-medium);
  line-height: 1;
  border-bottom: calc(100vw* 1 / 1900) solid white;
}

#dashboard .content .website-release .website-release-item .desc {
  list-style: disc;
  font-weight: 300;
  font-size: var(--font-size-desc-normal);
  line-height: 1.2;
  margin-bottom: var(--spacing-smaller);
  padding-left: var(--spacing-normal);
}

#dashboard .library {
  background-color: var(--color-dark);
}

#dashboard .library.light {
  background-color: var(--color-light);
}

#dashboard .library .library-nav {
  list-style: none;
  padding: var(--spacing-smallest);
}

#dashboard .library .library-nav a {
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  color: var(--color-light);
  padding: var(--spacing-smallest) var(--spacing-smaller);
  border-radius: var(--border-radius-small);
  margin-bottom: var(--spacing-smallest);
  display: block;
  text-decoration: none;
  transition: var(--transition);
}

#dashboard .library.light .library-nav a {
  color: var(--color-dark);
}

#dashboard .library .library-nav a:hover,
#dashboard .library .library-nav a.active {
  background-color: var(--color-grey-lighter);
  border-radius: var(--border-radius-small);
  transition: var(--transition);
}

#dashboard .btns-holder {
  display: flex;
  column-gap: var(--spacing-smaller);
  row-gap: calc(var(--spacing-smaller));
  flex-wrap: wrap;
  background: var(--color-dark);
  align-items: center;
  justify-content: center;
  padding: var(--spacing-normal) !important;
}

#dashboard .btns-holder .btn {
  display: flex;
  flex-direction: column;
  width: calc(100vw * 250/1900);
  height: calc(100vw * 250/1900);
  background: var(--color-grey-dark);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-smaller);
  color: var(--color-light);
  text-decoration: none;
  position: relative;
  transition: var(--transition);
}

#dashboard .btns-holder .btn:hover {
  background: var(--color-grey-light);
  transition: var(--transition);
}

#dashboard .btns-holder .btn .name {
  font-weight: 500;
  font-size: var(--font-size-desc-medium);
  line-height: var(--font-size-desc-big);
  height: calc(100vw * 45/1900);
  margin-bottom: var(--spacing-smaller);
}

#dashboard .btns-holder .btn .desc {
  font-weight: 300;
  font-size: var(--font-size-desc-normal);
  line-height: var(--font-size-desc-normal);
  padding-right: var(--spacing-medium);
  margin-bottom: 0;
  transition: var(--transition);
  height: calc(100vw * 140/1900);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

#dashboard .btns-holder .btn svg {
  position: absolute;
  right: var(--spacing-smallest);
  bottom: var(--spacing-smaller);
  font-size: var(--font-size-title-smaller);
  color: var(--color-light);
}

#dashboard .coming-soon {
  padding: var(--spacing-medium);
}

#dashboard .coming-soon h1 {
  font-size: var(--font-size-title-small);
  font-weight: 300;
}

#dashboard .materials {
  background-color: var(--color-light);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--spacing-small);
  padding: var(--spacing-smallest) var(--spacing-smallest) var(--spacing-biggest) !important;
}

#dashboard .materials .material .img-thumbnail {
  width: 100%;
  border: calc(100vw * 1/1900) solid var(--color-grey-lightest);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  margin: 0 0 var(--spacing-smallest);
  text-align: center;
}

#dashboard .materials .material .title,
#dashboard .materials .material .updated {
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  line-height: 1;
  text-transform: capitalize;
  color: var(--color-dark);
  margin: 0 0 var(--spacing-smallest);
  padding: 0 var(--spacing-smallest);
}

#dashboard .materials .material .updated svg {
  color: var(--color-mrt-green);
}

#dashboard .materials .material .btns {
  display: flex;
  gap: var(--spacing-smallest)
}

#dashboard .materials .material .btns a,
#dashboard .materials .material .btns button {
  background-color: var(--color-grey-lightest);
  display: inline-block;
  color: var(--color-dark);
  font-size: var(--font-size-desc-normal);
  font-weight: 600;
  padding: var(--spacing-smallest);
  border-radius: var(--border-radius-small);
  border: none;
  position: relative;
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
}

#dashboard .materials .material .btns button .dd {
  display: grid;
  grid-template-columns: repeat(1, 5fr 1fr);
  grid-gap: var(--spacing-smallest);
  position: absolute;
  z-index: 2;
  font-size: var(--font-size-desc-normal);
  font-weight: 600;
  color: var(--color-dark);
  background-color: var(--color-grey-lightest);
  border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
  padding: var(--border-radius-small);
  width: 100%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}

#dashboard .materials .material .btns button:hover .dd {
  opacity: 1;
  visibility: visible;
  transition: var(--transition);
}

#dashboard .materials .material .btns button .dd>span {
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  color: var(--color-dark);
}

#dashboard .materials .material .btns button .dd>span.loc {
  text-align: left;
}

#dashboard .website-copy p,
#dashboard .mrt-builds p,
#dashboard .task-board p {
  text-align: right;
  background-color: var(--color-light);
  margin: 0;
}

#dashboard .website-copy p a,
#dashboard .mrt-builds p a,
#dashboard .task-board p a {
  margin: var(--spacing-smallest);
  padding: var(--spacing-smallest) var(--spacing-smaller);
  display: inline-block;
  background-color: var(--color-grey-blue);
  border-radius: var(--border-radius-smaller);
  font-size: var(--font-size-desc-normal);
  color: var(--color-grey-darker);
  text-decoration: none;
}

#dashboard .charts .row {
  margin: 0 0 120px;
}

#dashboard .charts button {
  color: #000;
  font-size: 16px;
  padding: 4px 8px;
  margin: 0 0 18px;
}

#dashboard .charts .wrapper select {
  font-size: 16px;
  padding: 4px 8px;
  margin: 0 0 12px;
}

#dashboard .charts h2.title {
  font-size: calc(100vw * 36/1900);
  font-weight: 300;
  line-height: calc(100vw * 59/1900);
  letter-spacing: 0;
  color: #FFFFFF;
  text-transform: none;
  margin: 0 0 calc(100vw* 8 / 1900);
}



/* small devices */
@media only screen and (max-width: 699px) {
  #dashboard .content {
    margin-top: calc(100vw* 90 / 399);
  }

  #dashboard .content.shoots iframe,
  #dashboard .content.website iframe,
  #dashboard .content.task-board iframe,
  #dashboard .content.fullheight iframe {
    height: calc(100vh - (100vw* 90 / 399));
  }

  #dashboard .content.campaigns iframe,
  #dashboard .content.website iframe {
    height: calc(100vw* 4500 / 399);
  }

  #dashboard .content.campaigns .content-body {
    display: flex;
    flex-direction: column;
  }

  #dashboard .content.campaigns .content-body .embed-container {
    order: 1;
  }

  #dashboard .content.campaigns .content-body h2 {
    order: 2;
    margin: var(--spacing-small);
    font-size: var(--font-size-desc-big);
  }

  #dashboard .content.campaigns .content-body .process-items {
    order: 3;
    flex-direction: column;
    padding: var(--spacing-small);
  }

  #dashboard .content.campaigns .process-items .process-item .arrow {
    display: none;
  }

  #dashboard .content.campaigns .process-items .process-item {
    padding: var(--spacing-small);
    border-radius: var(--border-radius-small);
  }

  #dashboard .content.campaigns .process-items .process-item .title {
    font-size: var(--font-size-desc-medium);
  }

  #dashboard .content.campaigns .process-items .process-item ul {
    padding-left: var(--spacing-normal);
  }

  #dashboard .content.website .row {
    flex-direction: column-reverse;
  }

  #dashboard .content.website .col {
    width: 100%;
  }

  #dashboard .library .col-10 {
    width: 25vw;
  }

  #dashboard .library .col-90 {
    width: 75vw;
  }

  #dashboard .materials {
    grid-template-columns: repeat(1, 1fr);
    padding: var(--spacing-smaller) var(--spacing-smaller) var(--spacing-biggest) !important;
  }

  #dashboard .materials .material {
    width: 85%;
  }

}