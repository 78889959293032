#login {
  height: 100vh;
  overflow: hidden;
}
#login .video-background {
  position: absolute;
  filter: blur(calc(100vw * 55/1920));
  width: 100%;
}
#login .video-background video {
  width: 100%;
  height: 100vh;
}
#login .row {
  height: 100vh;
  align-items: center;
}
#login .form-holder {
  max-width: calc(100vw * 450/1920);
  margin: 0 auto;
  position: relative;
}
#login .form-holder form {
  padding: var(--spacing-small);
  background-color: var(--color-grey);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-regular);
  box-shadow: var(--shadow);
  position: relative;
}
#login .form-holder form div {
  display: flex;
  align-items: center;
  position: relative;
}
#login .form-holder form .field-icon {
  display: inline-block;
  position: absolute;
  left: calc(100vw * 5/1920);
}
#login .form-holder form .field-icon svg {
  height: var(--font-size-desc-small);
  color: var(--color-light);
}
#login .form-holder form input {
  font-family: var(--font-family-default);
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  color: white;
  background-color: transparent;
  border: none;
  padding: var(--spacing-smaller) var(--spacing-smaller) var(--spacing-smaller) var(--spacing-normal);
  width: 100%;
}
#login .form-holder form input:focus{
  outline: none;
}
#login .form-holder form input#username {
  border-bottom: calc(100vw * 1/1920) solid var(--color-grey-dark);
}
#login .form-holder form button {
  font-size: var(--font-size-desc-big);
  font-weight: 600;
  position: absolute;
  width: calc(100vw * 75/1920);
  height: calc(100vw * 75/1920);
  border-radius: 50%;
  top: calc((100vw * 75/1920)/2);
  right: calc(-1 * (100vw * 75/1920)/2);
  border: var(--spacing-smallest) solid var(--color-dark);
  background-color: var(--color-grey-light);
  color: var(--color-grey-darker);
  cursor: pointer;
}
#login .form-holder form button:hover {
  color: var(--color-dark);
  background-color: var(--color-light);
}
#login .form-holder #logo .img-holder {
  text-align: center;
  margin-bottom: var(--spacing-normal);
}
#login .form-holder .error-message {
  font-size: var(--font-size-desc-normal);
  font-weight: 300;
  border-radius: calc(100vw * 10/1920);
  background-color: rgba(255,255,255,0.35);
  max-width: 75%;
  margin: calc(100vw * 15/1920) auto;
  padding: calc(100vw * 15/1920);
  color: var(--color-grey-darker);
  position: absolute;
  left: 12.5%;
}


/* medium devices */
@media only screen and ( max-width: 1399px ) {
  #login .form-holder form button {
    width: calc(100vw * 55/1199);
    height: calc(100vw * 55/1199);
    top: calc((100vw * 55/1199)/2);
    right: calc(-1 * (100vw * 55/1199)/2);
  }
  #login .form-holder form button {
    font-size: var(--font-size-desc-big);
  }
}

/* small devices */
@media only screen and ( max-width: 699px ) {
  #login .form-holder {
    max-width: var(--section-width-wide);
  }
  #login .form-holder form button {
    width: calc(100vw * 45/399);
    height: calc(100vw * 45/399);
    top: calc((100vw * 25/399)/2);
    right: calc(-1 * (100vw * 45/399)/2);
  }
  #login .form-holder form button {
    font-size: var(--font-size-desc-medium);
  }
}