/* Globals */
:root {
	--font-family-default: 'Aktiv Grotesk';

	--font-size-title-biggest: calc(100vw * 80/1920);
	--font-size-title-bigger: calc(100vw * 72/1920);
	--font-size-title-big: calc(100vw * 66/1920);
	--font-size-title-medium: calc(100vw * 60/1920);	
	--font-size-title: calc(100vw * 54/1920);
	--font-size-title-small: calc(100vw * 48/1920);
	--font-size-title-smaller: calc(100vw * 36/1920);
	--font-size-title-smallest: calc(100vw * 32/1920);
	--font-size-desc-bigger: calc(100vw * 28/1920);
	--font-size-desc-big: calc(100vw * 24/1920);
	--font-size-desc-medium: calc(100vw * 20/1920);
	--font-size-desc-normal: calc(100vw * 16/1920);
	--font-size-desc-small: calc(100vw * 12/1920);
	--font-size-desc-smaller: calc(100vw * 8/1920);
	--font-size-desc-smallest: calc(100vw * 4/1920);

  --section-width-full: 100%;
  --section-width-wider: calc(100vw * 1830/1920);
  --section-width-wide: calc(100vw * 1600/1920);
  --section-width-boxed: calc(100vw * 1450/1920);

	--spacing-biggest: calc(100vw * 85/1920);
	--spacing-bigger: calc(100vw * 65/1920);
	--spacing-big: calc(100vw * 55/1920);
	--spacing-medium: calc(100vw * 45/1920);
	--spacing-normal: calc(100vw * 35/1920);
	--spacing-small: calc(100vw * 25/1920);
	--spacing-smaller: calc(100vw * 15/1920);
	--spacing-smallest: calc(100vw * 10/1920);

  --transition: all 0.3s ease-in-out;

  --color-dark: #000000;
  --color-light: #ffffff;
  --color-grey: rgba(42, 42, 44, 0.85);
  --color-grey-dark: rgba(32, 32, 32, 1);
  --color-grey-darker: rgba(20, 20, 20, 1);
	--color-grey-dark-opac: rgba(155, 155, 155, 0.05);
	--color-grey-darker-opac: rgba(128, 128, 128, 0.05);
  --color-grey-light: rgba(63, 63, 63, 1);
  --color-grey-lighter: rgb(146, 146, 146);
	--color-grey-lightest: rgb(223, 223, 223);
  --color-grey-light-opac: rgba(63, 63, 63, 0.5);
  --color-grey-lighter-opac: rgba(146, 146, 146, 0.5);
	--color-grey-blue: #E5E9F0;
  --color-gradient: linear-gradient(90deg, rgb(17, 17, 20) 0%, rgba(18,21,27,1) 18%, rgb(11, 11, 15) 48%, rgba(0,0,0,1) 100%);
	--color-gradient-invert: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(7, 7, 8, 0.5 ) 55%, rgba(14, 14, 15, 0.5) 100%);
	--color-warning: #a32929;
	--color-success: #196b19;
	--color-success-light: #c9f8c9;
	--color-mrt-green: #9C3;

	--border-radius-regular: calc(100vw * 15/1920);
	--border-radius-small: calc(100vw * 8/1920);
	--border-radius-smaller: calc(100vw * 3/1920);

  --shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}
* {
	transition: var(--transition);
	box-sizing: border-box;
}
.react-datepicker-wrapper input {
	color:#000000;
	font-size: 16px;
	margin: 0 0 8px;
}
html {
	scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
	overflow-x: hidden;
}
html {
	background: var(--color-dark);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
span,
label,
input,
button {
  font-family: var(--font-family-default);
  line-height: 1;
  word-break: break-word;
	margin: 0;
	padding: 0;
	color: #ffffff;
}
figure > img {
	width: 100%;
}
ul {
  list-style: none;
}
content {
	display: block;
}
.container {
  padding: 0;
  margin: 0 auto;
  position: relative;
	z-index: 1;
}
.full {
  max-width: var(--section-width-full);
}
.wide {
  max-width: var(--section-width-wide);
}
.wider {
  max-width: var(--section-width-wider);
}
.boxed {
  max-width: var(--section-width-boxed);
}
.row {
	display: flex;
	width: 100%;
}
.col.col-5 {
	width: 5%;
}
.col.col-10 {
	width: 10%;
}
.col.col-15 {
	width: 15%;
}
.col.col-20 {
	width: 20%;
}
.col.col-25 {
	width: 25%;
}
.col.col-30 {
	width: 30%;
}
.col.col-33 {
	width: 33.33%;
}
.col.col-40 {
	width: 40%;
}
.col.col-45 {
	width: 45%;
}
.col.col-50 {
	width: 50%;
}
.col.col-55 {
	width: 55%;
}
.col.col-60 {
	width: 60%;
}
.col.col-67 {
	width: 67%;
}
.col.col-70 {
	width: 70%;
}
.col.col-75 {
	width: 75%;
}
.col.col-80 {
	width: 80%;
}
.col.col-85 {
	width: 85%;
}
.col.col-90 {
	width: 90%;
}
.col.col-95 {
	width: 95%;
}
.col.col-100 {
	width: 100%;
}
.title {
	font-size: var(--font-size-title);
	line-height: 1.2;
	margin-bottom: var(--spacing-medium);
}
.desc {
	font-size: var(--font-size-desc-normal);
	line-height: 1.2;
	margin-bottom: var(--spacing-small);
}

.title.size-biggest {
	font-size: var(--font-size-title-biggest);
}
.title.size-bigger {
	font-size: var(--font-size-title-bigger);
}
.title.size-big {
	font-size: var(--font-size-title-big);
}
.title.size-small {
	font-size: var(--font-size-title-small);
	margin-bottom: var(--spacing-small);
}
.title.size-smaller {
	font-size: var(--font-size-title-smaller);
	margin-bottom: var(--spacing-small);
}
.title.size-smallest {
	font-size: var(--font-size-title-smallest);
	margin-bottom: var(--spacing-small);
}

.size-medium {
	font-size: var(--font-size-desc-medium);
}
.weight-light {
	font-weight: 300;
}

.color-mrt-green {
	color: var(--color-mrt-green);
}

.flex {
	display: flex;
}

form .row {
	gap: var(--spacing-small);
	margin-bottom: var(--spacing-smaller);
}

.desktop {
	display: block;
}
.mobile {
	display: none;
}
.visually-hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.btn {
  display: inline-block;
  font-size: var(--font-size-desc-small);
  background: var(--color-grey-light);
	border: calc(100vw * 1/1920) solid var(--color-grey-light);
  border-radius: calc(100vw * 8/1920);
  padding: var(--spacing-smaller);
  color: var(--color-light);
  text-decoration: none;
  opacity: 0.8;
  transition: var(--transition);
	cursor: pointer;
}
.btn svg {
	margin-right: var(--spacing-smallest);
}
.btn:hover {
  opacity: 1;
  transition: var(--transition);
}
.btn-hollow {
	background-color: transparent;
	border: calc(100vw * 1/1920) solid var(--color-grey-light);
}

.clear {
	flex-basis: 100%;
	height: 0;
}
.error-message {
	color: red;
	margin-top: 10px;
	font-size: 14px;
}


/* Scroll Bar */
/* width */
::-webkit-scrollbar {
	width: calc(100vw * 8/1900);
}
/* Track */
::-webkit-scrollbar-track {
	background: var(--color-grey-dark);
	border-radius: calc(100vw * 8/1900);
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--color-grey-light);
	border: calc(100vw * 2/1900) solid transparent;
	border-radius: calc(100vw * 8/1900);
	transition: background 0.3s ease-in-out;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--color-grey-lighter);
	border: calc(100vw * 2/1900) solid transparent;
	transition: background 0.3s ease-in-out;
}

/* medium devices */
@media only screen and ( max-width: 1399px ) {
	:root {	
		--font-size-title-biggest: calc(100vw * 60/1199);
		--font-size-title-bigger: calc(100vw * 52/1199);
		--font-size-title-big: calc(100vw * 48/1199);
		--font-size-title-medium: calc(100vw * 44/1199);	
		--font-size-title: calc(100vw * 40/1199);
		--font-size-title-small: calc(100vw * 36/1199);
		--font-size-title-smaller: calc(100vw * 32/1199);
		--font-size-title-smallest: calc(100vw * 28/1199);
		--font-size-desc-bigger: calc(100vw * 28/1199);
		--font-size-desc-big: calc(100vw * 24/1199);
		--font-size-desc-medium: calc(100vw * 20/1199);
		--font-size-desc-normal: calc(100vw * 16/1199);
		--font-size-desc-small: calc(100vw * 12/1199);
		--font-size-desc-smaller: calc(100vw * 10/1199);
		--font-size-desc-smallest: calc(100vw * 8/1199);
	
		--section-width-full: 100%;
		--section-width-wider: calc(100vw * 1100/1199);
		--section-width-wide: calc(100vw * 1000/1199);
		--section-width-boxed: calc(100vw * 950/1199);
	
		--spacing-bigger: calc(100vw * 75/1199);
		--spacing-big: calc(100vw * 45/1199);
		--spacing-medium: calc(100vw * 35/1199);
		--spacing-normal: calc(100vw * 25/1199);
		--spacing-small: calc(100vw * 15/1199);
		--spacing-smaller: calc(100vw * 10/1199);
		--spacing-smallest: calc(100vw * 5/1199);
	
		--border-radius-regular: calc(100vw * 10/1199);
		--border-radius-small: calc(100vw * 5/1199);
	}
}

/* small devices */
@media only screen and ( max-width: 699px ) {
	:root {	
		--font-size-title-biggest: calc(100vw * 52/399);
		--font-size-title-bigger: calc(100vw * 48/399);
		--font-size-title-big: calc(100vw * 44/399);
		--font-size-title-medium: calc(100vw * 40/399);	
		--font-size-title: calc(100vw * 36/399);
		--font-size-title-small: calc(100vw * 32/399);
		--font-size-title-smaller: calc(100vw * 28/399);
		--font-size-title-smallest: calc(100vw * 24/399);
		--font-size-desc-bigger: calc(100vw * 24/399);
		--font-size-desc-big: calc(100vw * 20/399);
		--font-size-desc-medium: calc(100vw * 16/399);
		--font-size-desc-normal: calc(100vw * 12/399);
		--font-size-desc-small: calc(100vw * 10/399);
		--font-size-desc-smaller: calc(100vw * 8/399);
		--font-size-desc-smallest: calc(100vw * 6/399);
	
		--section-width-full: 100%;
		--section-width-wider: calc(100vw * 350/399);
		--section-width-wide: calc(100vw * 300/399);
		--section-width-boxed: calc(100vw * 250/399);
	
		--spacing-bigger: calc(100vw * 55/399);
		--spacing-big: calc(100vw * 35/399);
		--spacing-medium: calc(100vw * 25/399);
		--spacing-normal: calc(100vw * 15/399);
		--spacing-small: calc(100vw * 10/399);
		--spacing-smaller: calc(100vw * 5/399);
		--spacing-smallest: calc(100vw * 5/399);
	
		--border-radius-regular: calc(100vw * 10/399);
		--border-radius-small: calc(100vw * 5/399);
	}
}